// 这个主要是路由表组件的写法
import React, {Suspense} from 'react'
import {useRoutes, Navigate} from 'react-router-dom'
import {RouteTable, Routes} from './routeTable'

/**
 * 递归设置路由表信息
 * @param table
 * @returns
 */
const syncRouter = (table: Routes[]): any[] => {
  const mRouteTable: any[] = []
  table.forEach(route => {
    mRouteTable.push({
      path: route.path,
      element: (
        <Suspense>
          {route.path === '/' ? <Navigate to="/tmc/tmclist"/> : <route.component/>}
        </Suspense>
      ),
      children: route.children && syncRouter(route.children)
    })
  })
  return mRouteTable
}

// 直接暴露成一个组件调用
export default () => useRoutes(syncRouter(RouteTable))
