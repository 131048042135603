// router
import RouteTable from './router'
// react
import React, {useEffect, useState} from 'react'

const App = () => {
  window.parent && window.parent.postMessage({type: 'loadSuccess', value: true}, "*")
  return <RouteTable/>
}

export default App
