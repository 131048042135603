import {lazy} from 'react'

export interface Routes {
  path: string
  component: React.LazyExoticComponent<any>
  children?: Routes[]
}

// 布局页面
const LayoutPage = lazy(() => import('@/layout'))
// 404页面
const NotFoundPage = lazy(() => import('@/pages/404'))


export const RouteTable: Routes[] = [
  {
    path: '/',
    component: LayoutPage
  },
  {
    path: '/tmc',
    component: LayoutPage,
    children: [
      {
        path: 'tmclist',
        component: lazy(() => import('@/pages/tmc'))
      },
      {
        path: 'companylist',
        component: lazy(() => import('@/pages/company'))
      },
    ]
  },
  {
    path: '*',
    component: NotFoundPage
  }
]
